/** @jsx jsx */

import { jsx } from 'theme-ui'

export function GenericSectionDivider({ fill, sx = {}, ...props }) {
  return (
    <svg
      {...props}
      sx={{ transform: 'translateY(-2px)', ...sx }}
      strokeWidth={0}
      width="100%"
      height="190"
      xmlns="http://www.w3.org/2000/svg"
    >
      <defs>
        <pattern
          id={`bg${fill}`}
          overflow="visible"
          stroke={fill}
          patternUnits="userSpaceOnUse"
          width="1321"
          height="191"
          viewBox="0 0 1321 191"
        >
          <path
            d="M0.280273 40.6195C19.6115 29.0991 49.5749 15.452 87.9151 14.7431C153.641 13.6797 178.127 71.1041 252.875 75.3577C326.011 79.4342 341.798 28.0357 415.579 23.9593C545.098 17.0471 581.828 187.016 706.836 190.207C846.343 193.751 903.048 1.09592 1027.73 13.8569C1119.56 23.2504 1149.52 93.4359 1227.81 90.6001C1270.34 89.005 1300.63 63.8374 1320.28 40.6195C1320.28 22.8736 1320.28 10.9266 1320.28 0.754883L660.28 0.754883L0.280273 0.754883L0.280273 40.6195Z"
            fill={fill}
            stroke={fill}
            strokeWidth="3"
          />
        </pattern>
      </defs>
      <rect width="100%" height="100%" fill={`url(#bg${fill})`} />
    </svg>
  )
}
