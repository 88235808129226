/**@jsx jsx */
import { Flex, Box, jsx } from 'theme-ui'

import Heading from '~/components/IntlComponents/Heading'
import RichTextRenderer from '~/components/Content/RichText'
import {
  detectLanguageString,
  localeFromPathname,
} from '~/utils/intl/localePrefix'
import { useRef } from 'react'

const TextBlock = ({ heading, body, ...props }) => {
  const ref = useRef()
  const lang = detectLanguageString(ref.current?.innerHTML)
  return (
    <Flex
      sx={{
        flexDirection: 'column',
        mt: ['40px', '60px'],
        'h1, h2, h3, h4': { fontWeight: 'heading', m: 0, mb: 4 },
      }}
      {...props}
    >
      {heading && <Heading sx={{ fontSize: ['32px', 5] }}>{heading}</Heading>}
      {body && (
        <Box
          sx={{
            fontSize: ['18px', 3],
            p: { mt: 0, mb: 4, ':last-child': { mb: 0 } },
            lineHeight: '120%',
            h2: { fontSize: 4 },
            'li p': { m: 0, lineHeight: '144%' },
            a: { textDecoration: 'underline' },
          }}
        >
          <RichTextRenderer
            ref={ref}
            lang={lang}
            sx={{
              fontSize: 3,
            }}
            richText={body}
          />
        </Box>
      )}
    </Flex>
  )
}

export default TextBlock
