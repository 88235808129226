import { graphql, useStaticQuery } from 'gatsby'

const useArticlesQuery = () => {
  const data = useStaticQuery(
    graphql`
      query ArticlesQuery {
        articles: allContentfulArticle(
          filter: { slug: { ne: "dummy-article" } }
        ) {
          nodes {
            id
            title
            slug
            tags
            isFeatured
            description {
              childMarkdownRemark {
                html
              }
            }
            image {
              id
              title
              gatsbyImageData(width: 500, quality: 80)
            }
            publishDate(formatString: "x|MMMM D, YYYY")
          }
        }
      }
    `,
  )

  return data
}

export default useArticlesQuery
