/**@jsx jsx */
import { Flex, jsx } from 'theme-ui'

import { GatsbyImage } from 'gatsby-plugin-image'
import Text from '~/components/IntlComponents/Text'

const ImageWithSubtitle = ({ image, subtitle }) => {
  return (
    <Flex sx={{ flexDirection: 'column', alignItems: 'center' }}>
      {image && image.gatsbyImageData && (
        <GatsbyImage
          image={image.gatsbyImageData}
          alt={image.title}
          sx={{
            width: ['100%', '52%'],
            border: '2px solid',
            borderRadius: '8px',
          }}
        />
      )}
      {subtitle && (
        <Text
          as="sub"
          sx={{
            textAlign: 'center',
            p: { m: 0 },
            mt: ['15px', '27px'],
            fontSize: [1, 2],
          }}
          dangerouslySetInnerHTML={{
            __html: subtitle.childMarkdownRemark.html,
          }}
        />
      )}
    </Flex>
  )
}

export default ImageWithSubtitle
