/* eslint-disable react-hooks/exhaustive-deps */

/**@jsx jsx */
import { Flex, Box, useThemeUI, jsx } from 'theme-ui'

import Heading from '~/components/IntlComponents/Heading'
import ArticleCard from '../Card'
import Slider from 'react-slick'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'

import ArrowLeft from '~/assets/images/icons/arrow-left.svg'
import ArrowRight from '~/assets/images/icons/arrow-right.svg'
import useArticlesQuery from '~/hooks/graphql/queries/use-articles'
import { useEffect, useState } from 'react'
import { GenericSectionDivider } from '~/components/Generic/SectionDivider'
import { useTranslate } from '@chordcommerce/gatsby-theme-autonomy'

const NextArrow = props => {
  const { className, style, onClick } = props

  return (
    <ArrowRight className={className} style={{ ...style }} onClick={onClick} />
  )
}

const PrevArrow = props => {
  const { className, style, onClick } = props
  return (
    <ArrowLeft className={className} style={{ ...style }} onClick={onClick} />
  )
}

const settings = {
  dots: true,
  infinite: true,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
  centerMode: true,
  centerPadding: '15px',
  nextArrow: <NextArrow />,
  prevArrow: <PrevArrow />,
}

const RelatedArticles = ({ article }) => {
  const getArticles = (articles, newer = false) => {
    return articles.filter((articleNode, idx) => {
      const currentArticleDate = (
        article?.publishDate?.split('|') || []
      ).shift()
      const articleNodeDate = (
        articleNode?.publishDate?.split('|') || []
      ).shift()
      const isCurrentArticle = article.slug === articleNode.slug
      const articleIdx = articles.findIndex(
        art => art.slug === articleNode.slug,
      )
      const isDuplicate = articleIdx !== idx

      return newer
        ? parseInt(articleNodeDate) >= parseInt(currentArticleDate) &&
            !isCurrentArticle &&
            !isDuplicate
        : parseInt(articleNodeDate) <= parseInt(currentArticleDate) &&
            !isCurrentArticle &&
            !isDuplicate
    })
  }
  const [relatedArticles, setRelatedArticles] = useState([])

  const articles = useArticlesQuery()

  const translate = useTranslate()

  useEffect(() => {
    if (articles?.articles?.nodes) {
      const articleNodes = articles.articles.nodes
      const olderArticles = getArticles(articleNodes)
      let relatedToState = []
      if (olderArticles.length > 0) {
        const sortedOlderArticles = olderArticles.sort(
          (a, b) =>
            parseInt(b.publishDate.split('|')[0]) -
            parseInt(a.publishDate.split('|')[0]),
        )
        relatedToState.push(...sortedOlderArticles)
      }

      const newerArticles = getArticles(articleNodes, true)
      if (newerArticles.length > 0) {
        const sortedNewerArticles = newerArticles.sort(
          (a, b) =>
            parseInt(a.publishDate.split('|')[0]) -
            parseInt(b.publishDate.split('|')[0]),
        )
        relatedToState = [...sortedNewerArticles, ...relatedToState]
      }
      setRelatedArticles(relatedToState)
    }
  }, [])
  const {
    theme: { rawColors },
  } = useThemeUI()
  return (
    relatedArticles.length > 0 && (
      <Box>
        <GenericSectionDivider
          sx={{
            transform: [
              'rotate(180deg) scale(28%, 28%) translateX(137%)',
              'rotate(180deg) translateX(13%)',
            ],
            mb: ['-76px', '-10px'],
            width: ['1548%', '210%'],
            shapeRendering: 'crispEdges',
          }}
          fill={rawColors.background}
        />
        <Flex
          sx={{
            flexDirection: 'column',
            padding: ['40px 0px 87px', '70px 40px 120px'],
            backgroundColor: rawColors.background,
            textAlign: 'center',
            gap: '60px',
          }}
        >
          <Heading sx={{ fontSize: ['22px', 5], color: rawColors.text }}>
            {translate('gooder_times_are_here')}
          </Heading>
          <Flex
            sx={{
              gap: '60px',
              m: '0 auto',
              display: ['none', 'flex'],
              '& > a': {
                flexBasis: 0,
                flexGrow: 1,
                maxWidth: '315px',
              },
            }}
          >
            {relatedArticles.slice(0, 3).map(article => (
              <ArticleCard
                key={'desktop-' + article.id}
                article={article}
                ignoreFeatured={true}
              />
            ))}
          </Flex>
          <Slider
            {...settings}
            sx={{
              variant: 'responsive.mobile',
              '.slick-track': {
                display: 'flex !important',
              },
              '.slick-slide': {
                padding: '0 15px',
                height: 'inherit !important',
                '& > div': { height: '100%' },
              },
              '.slick-prev, .slick-next': {
                zIndex: 1,
                width: '37px',
                height: '37px',
                top: '52%',
              },

              '.slick-prev': {
                left: '14px',
              },
              '.slick-next': {
                right: '14px',
              },
              a: {
                border: 'none',
                flexBasis: 0,
                flexGrow: 1,
              },
              'a > div': {
                border: '2px solid',
                borderRadius: '4.6px',
              },

              '.slick-dots button:before': {
                content: 'none',
              },
              '.slick-dots li': {
                width: '12px',
                height: '12px',
                margin: '0 5px',
              },
              '.slick-dots button': {
                width: '100%',
                height: '100%',
                margin: '0',
                border: '1px solid',
                borderColor: 'waxFlower',
                backgroundColor: 'transparent',
                borderRadius: '50%',
              },

              '.slick-dots .slick-active button': {
                backgroundColor: 'waxFlower',
              },
            }}
          >
            {relatedArticles.slice(0, 3).map(article => (
              <ArticleCard
                key={'mobile-' + article.id}
                article={article}
                ignoreFeatured
              />
            ))}
          </Slider>
        </Flex>
      </Box>
    )
  )
}

export default RelatedArticles
